<template>
  <div>
    <div class="mt-3" />
    <b-container v-if="loading">
      <div class="text-center">
        <b-spinner label="Loading..." variant="warning"></b-spinner>
      </div>
    </b-container>
    <span v-if="!loading">
      <h5 class="text-center text-primary">Session Management</h5>
      <div class="mt-3" />
      <b-container fluid>
        <b-row>
          <b-col md="auto">
            <div class="mt-4" />
            <strong class="text-primary">Step 1: Choose date*</strong><br/>
            <b-calendar v-model="value" @context="onContext" locale="en-US" :date-info-fn="dateClass" start-weekday="1"></b-calendar><br/>
            <div class="mt-3" />
            <strong><span class="text-primary">*</span> Sessions are planned on highlighted dates</strong>
          </b-col>
          <b-col>
            <div class="mt-4" />
            <strong class="text-primary">Step 2: Set details</strong>
            <b-row>
              <b-col>
                <b-form @submit.prevent="onSubmit" @reset.prevent>
                  <b-form-group id="input-group-1" label="Title" label-for="input-1">
                    <b-form-input
                      id="input-1"
                      type="text"
                      v-model="form.title"
                      placeholder="Enter title"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group id="input-group-2" label="Agenda" label-for="input-2">
                     <b-form-textarea
                      id="input-2"
                      type="text"
                      v-model="form.description"
                      placeholder="Enter complete agenda for the session"
                      rows="8"
                    ></b-form-textarea>
                  </b-form-group>

                  <b-form-group id="input-group-3" label="Time (Eindhoven time zone)" label-for="input-3">
                    <b-form-timepicker v-model="form.time" locale="en"></b-form-timepicker>
                  </b-form-group>

                  <b-form-group id="input-group-3" label="Deadline to submit attendance (for both teachers and students)" label-for="input-3">
                    <b-form-datepicker id="input-3" v-model="form.deadlineSubmissionDate" class="mb-2"></b-form-datepicker>
                    <b-form-timepicker v-model="form.deadlineSubmissionTime" locale="en"></b-form-timepicker>
                    <div class="mt-2">Deadline: {{ form.deadlineSubmissionDate }} {{ form.deadlineSubmissionTime }}</div>
                  </b-form-group>

                  <b-button type="submit" variant="primary">Add new/update existing</b-button>
                  <b-button type="reset" variant="secondary" @click="deleteSession">Delete existing</b-button>
                </b-form>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>

      <div class="mt-3" />
      <b-alert 
        :show="dismissCountDown" 
        dismissible 
        variant="success" 
        @dismissed="dismissCountDown=0"
        @dismiss-count-down="countDownChanged"
        >
        {{ alertMessage }}
      </b-alert>
      <b-alert
        :show="dismissErrCountDown"
        dismissible
        variant="danger"
        @dismissed="dismissErrCountDown=0"
        @dismiss-count-down="countDownErrChanged"
      >
        {{ alertErrMessage }}
      </b-alert>
    </span>

    <div class="mt-3" />
  </div>
</template>

<script>
  import SessionService from '@/services/SessionService.js';

  export default {
    name: 'jadwal',
    title: "TPA Gembira - Session Management",
    data() {
      return {
        alertMessage: "",
        dismissSecs: 5,
        dismissCountDown: 0,
        dismissErrSecs: 5,
        dismissErrCountDown: 0,
        alertErrMessage: "",
        loading: false,
        value: '',
        context: null,
        dateWithSessionOptions: [],
        form: {
          date: "",
          title: "",
          description: "",
          time: "",
          deadlineSubmissionDate: "",
          deadlineSubmissionTime: ""
        },
      }
    },
    created(){
      this.init();
    },
    methods: {
      dateClass(ymd, date) {
        if (this.dateWithSessionOptions.includes(date.getFullYear()+ '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2))){
          return 'table-info';
        } else {
          return '';
        }
      },
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
      countDownErrChanged(dismissErrCountDown) {
        this.dismissErrCountDown = dismissErrCountDown
      },
      showSuccessConfirm(msg) {
        this.dismissCountDown = this.dismissSecs;
        this.alertMessage = msg;
      },
      showErrorConfirm(msg){
        this.dismissErrCountDown = this.dismissErrSecs
        this.alertErrMessage = msg;
      },
      onContext(ctx) {
        this.context = ctx;
        this.form.date = ctx.selectedYMD;

        if (ctx.selectedYMD !== null && ctx.selectedYMD !== ""){
          SessionService.getSessionDetails(ctx.selectedYMD)
            .then(
              (item => {
                this.form.time = item.time;
                this.form.title = item.title;
                this.form.description = item.description;
                this.form.deadlineSubmissionDate = item.deadlineSubmissionDate;
                this.form.deadlineSubmissionTime = item.deadlineSubmissionTime;
              })
            );
        }
      },
      async init(){
        this.loading = true;
        await Promise.all ([this.getDateWithSession()])
          .then(() => {
            this.loading = false;
          });
      },
      async getDateWithSession(){
        SessionService.getListSessions()
          .then(
            (list => {
              // users for visualization only
              for (let item of list){
                this.dateWithSessionOptions.push(item.date);
              }
            })
          );
      },
      onSubmit(){
        if (this.form.date == "" || this.form.time == "" || typeof(this.form.time) === "undefined"){
            this.showErrorConfirm("Please choose a date and time for a session")
        } else {
          if (this.form.deadlineSubmissionDate == "" || this.form.deadlineSubmissionTime == "" 
            || typeof(this.form.deadlineSubmissionDate) === "undefined"
            || typeof(this.form.deadlineSubmissionTime) === "undefined"){
            this.showErrorConfirm("Please choose a proper deadline submission date and time")
          } else {
            let sessionDateTime = new Date(this.form.date + "T" + this.form.time);
            let deadlineSubmissionDateTime = new Date(this.form.deadlineSubmissionDate + "T" + this.form.deadlineSubmissionTime);
            if (sessionDateTime < deadlineSubmissionDateTime){
              this.showErrorConfirm("Session date must be before deadline submission for attendance")
            } else {
              SessionService.upsertSessionDetails(this.form);
              this.showSuccessConfirm("Session update submitted successfully");
              this.dateWithSessionOptions.push(this.form.date);
            }
          }
        }
      },
      deleteSession(){
        if (this.form.date == ""){
            this.showErrorConfirm("Please choose a date")
        } else {
          if (!this.dateWithSessionOptions.includes(this.form.date)){
            this.showErrorConfirm("Please choose a date with a session planned")
          } else {
            SessionService.deleteSession(this.context.activeYMD);
            this.showSuccessConfirm("Session delete submitted successfully");
            this.dateWithSessionOptions.splice(this.dateWithSessionOptions.indexOf(this.form.date), 1);
          }
        }
      }
    }
  } 
</script>

<style lang="sass" scoped>
</style>